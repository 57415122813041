import React from 'react';

import './styles/Modal.css'

class FailModal extends React.Component {
	render() {
		return(
		<div id="myModal" class="modal">
			<div class="fail-modal-container">
				<h1 className="fail-modal-header">Unfortunately, There Was An Issue Setting Up Your Account</h1>
				<h2 className="fail-modal-second-header">Please reach out to us and we'll get your account setup right away</h2>
				<a className="fail-modal-link" href="https://www.therapeuticnutrition.org/#comp-ihenvibb2" target="_blank" rel="noreferrer"><button className="fail-modal-button">Contact Us</button></a>

			</div>

		</div>
		)
	}
}

export default FailModal;