import React from 'react';

import './styles/Header.css';

class Header extends React.Component {
    render() {
        return (
            <header className='header-container'>
                <div className="secondary-header-logo-container">
                    <a href="https://www.therapeuticnutrition.org/"><img src="/images/iptn-logo.webp" alt="" className='secondary-header-logo'/></a>
                </div>

                <div className='header-back-container'>
                    <h2 className="header-back-button-text">HEAD BACK TO IPTN HOME PAGE</h2>
                    <a href="https://www.therapeuticnutrition.org/" className='header-back-link'>
                        <i class="fal fa-arrow-right header-back-arrow"></i>
                    </a>
                </div>


            </header>
        )
    }
}

export default Header;