import React from 'react';

import axios from 'axios';

import HeaderReg from '../components/HeaderReg.jsx';
import Footer from '../components/Footer.jsx';
import SuccessModal from '../components/SuccessModal.jsx';
import FailModal from '../components/FailModal.jsx';

import './styles/RegistrationPage.css';

class RegistrationPage extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        userExists: false,
        enrollSuccess: false,
        enrollFail: false
    }

    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {

        const userExists = this.state.userExists;
        let emailError;

        if (!userExists) {
            emailError = ""
        } else {
            emailError = 
                <p>A user account already exists with this email address</p>
        }

        return (
            <div className="registration-page" id="top">
                <HeaderReg />
                <div className="registration-page-container">
                    <div className="registration-page-header-container">
                        <h2 className="registration-page-header">Register For: <br/>Foundations in Therapeutic Nutrition - Pharmacist Training</h2>
                    </div>
                    
                    <div className="registration-page-form-container">
                        <form 
                            className="registration-page-form" 
                            name="registration"
                            method="POST"
                            data-netlify="true"
                            onSubmit={this.handleSubmit}
                        >
                            <input type="hidden" name="form-name" value="registration"/>
                            <p>
                                <label className="registration-page-contact-text">First Name<br/><input className="registration-page-input" type="text" name="firstName" id="firstName" onChange={this.textChange}  required/></label>   
                            </p>
                            <p>
                                <label className="registration-page-contact-text">Last Name<br/><input className="registration-page-input" type="text" name="lastName" id="lastName" onChange={this.textChange}  required/></label>   
                            </p>
                            <p>
                                <label className="registration-page-contact-text">Email<br/>
                                {emailError}
                                <input  className="registration-page-input" type="email" name="email" id="email" onChange={this.textChange} required/></label>
                            </p>
                            <p>
                                <button className="registration-page-submit-button" type="submit">Register</button>
                            </p>
                        </form>
                    </div>
                </div>
                <Footer />
                {this.state.enrollSuccess ? <SuccessModal /> : ""}
                {this.state.enrollFail ? <FailModal /> : ""}
            </div>
        )
    }

    textChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState(() => {
            return {
            [name]: value
            };
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            user: {
                name: this.state.firstName + " " + this.state.lastName,
                short_name: this.state.firstName + " " + this.state.lastName,
                sortable_name: this.state.firstName + " " + this.state.lastName,
                terms_of_use: true,
                skip_registration: true
            },
            pseudonym: {
                unique_id: this.state.email,
            }
        }

        axios.post("/.netlify/functions/createUser", data)
            .then(res =>{
                if (res.data.status === 400) {
                    this.setState({
                        firstName: '',
                        lastName: '',
                        email: '',
                        enrollFail: true
                    })
                } else {
                    this.setState({
                        firstName: '',
                        lastName: '',
                        email: '',
                        enrollSuccess: true
                    })
                }
                console.log(res.data)
            })
            .catch(error => {
                console.log(error)
            })
    }
}

export default RegistrationPage;