import React from 'react';

// Styles import
import './styles/Footer.css';

class Footer extends React.Component {
    render() {
        return (
            <footer className="information-page-footer">
                <div className="information-page-footer-text-container">
                    <h4 className="information-page-footer-text">TRAINING@THERAPEUTICNUTRITION.ORG</h4>
                </div>
            </footer>
        )
    }
}

export default Footer;