import React from 'react';

import './styles/Modal.css'

class SuccessModal extends React.Component {
	render() {
		return(
		<div id="myModal" class="modal">
			<div class="modal-container">
				<h1 className="modal-header">Registration Successful, Next Steps:</h1>
				<h2 className="modal-second-header">Step 1: Navigate To <a href="https://canvas.instructure.com/login/canvas" target="_blank" rel="noreferrer">Canvas Learning</a></h2>
				<h2 className="modal-second-header">Step 2: Click The "Forgot Password?" Link </h2>
				<h2 className="modal-second-header">Step 3: Setup Your New Canvas Password</h2>
				<h2 className="modal-second-header">Step 3: Begin Working On Your New Course</h2>
			</div>

		</div>
		)
	}
}

export default SuccessModal;