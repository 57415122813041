import React from 'react';

import './styles/Header.css';

class Header extends React.Component {
    render() {
        return (
            <header className='header-container'>
                <div className="secondary-header-logo-container">
                    <a href="https://www.therapeuticnutrition.org/"><img src="/images/iptn-logo.webp" alt="" className='secondary-header-logo'/></a>
                </div>
            </header>
        )
    }
}

export default Header;