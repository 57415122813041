import React from 'react';

import Header from '../components/Header.jsx';
import Footer from '../components/Footer.jsx';

import './styles/Home.css';

class Home extends React.Component {

    componentDidMount() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <div className="information-page">
                <div className="information-page-container">
                    <Header />
                    <section className="information-page-hero-container">
                        {/* <div className="information-page-hero-image-container-small">
                            <img src="/images/informationPage-hero-image.png" alt="Fitness trainer encouraging an athlete" className='information-page-hero-image-small'/>
                        </div>
                        <div className="information-page-hero-image-container-large">
                            <img src="/images/informationPage-hero-image-large.png" alt="Fitness trainer encouraging an athlete" className='information-page-hero-image-large'/>
                        </div> */}
                        <div className="information-page-hero-text-container">
                            <h3 className="information-page-hero-text">Training and Certification in Therapeutic Nutrition</h3>
                        </div>
                        
                    </section>
                    <section className="information-page-how-it-works">
                        <div className="information-page-subheader-container">
                            <h3 className="information-page-subheader">Who should take this training? </h3>
                        </div>
                        <ul className="information-page-unordered-list">
                            <li className="information-page-li-two">Health care practitioners who:</li>
                            <li className="information-page-li">Want to safely support patients to explore a variety of ‘food first’ approaches for managing chronic disease.</li>
                            <li className="information-page-li">Are concerned with the burden that rising rates of obesity and overweight, metabolic syndrome, and diabetes are placing on our health care system and society.</li>
                            <li className="information-page-li">Want a deeper understanding of the relationship between what we eat, our metabolic health, and the development of chronic disease.</li>
                            <li className="information-page-li">Want to learn how to safely de-prescribe insulin, glucose and blood pressure lowering drugs, etc. as a patient’s metabolic health improves.</li>
                            <li className="information-page-li">Are inspired by the possibility of achieving remission in type 2 diabetes using therapeutic nutrition.</li>
                            <li className="information-page-li">Are excited about changing clinical practice to align with a paradigm shift that is taking the  ‘chronic’ out of chronic disease.</li>
                        </ul>
                    </section>
                    <section className="information-page-why-this-program">
                        <div className="information-page-subheader-container">
                            <h3 className="information-page-subheader">How is it offered?</h3>
                        </div>
                        <div className="information-page-paragraph-container">
                            <p className="information-page-li">All of IPTN’s training courses are accredited by a discipline-specific accrediting organization and provide recognized CEU/CPD credits. </p>
                            <p className="information-page-li">Level 1 for all health professionals is a comprehensive introductory course called <em><b>Foundations in Therapeutic Nutrition</b></em>. It is offered as an online course that will take approximately 12 hours to complete and learners can work at their own pace. While the overall structure and core content is similar for all health care providers, there are separate versions of this course for physicians, pharmacists, dietitians, and nurses to address discipline-specific implications for clinical practice.  </p>
                            <img src="./images/iptn-course-structure.png" alt="IPTN Course Structure Document" className="information-page-course-image" />
                            {/* <p className="information-page-li"><b>Course Description</b></p>
                            <p className="information-page-li">As the incidence of obesity, type 2 diabetes, and other metabolically based chronic diseases skyrocket, health care providers have been part of many strategies to arrest this devastating trend. More recently, there is growing public interest in exploring a ‘food first’ approach to actively treating these diseases, and a growing evidence base to support the use of personalized, therapeutic nutrition to achieve remission as a clinical goal.</p>
                            <p className="information-page-li">In this course, learners will be introduced to the concept of personalized, therapeutic nutrition and the role that food and eating plays in chronic disease management. With a focus on patient safety, the course will explore topics such as the evolution of dietary patterns in Western Society, the relationship between diet, energy metabolism and the development of chronic disease and how health care providers can integrate personalized, therapeutic nutrition into their clinical toolboxes.</p> */}
                            <p className="information-page-li"><span>Level 2 for all health professionals</span> is a <b><em>6-hour Skill Building Workshop in Therapeutic Nutrition.</em></b> The pre-requisite for this workshop is satisfactory completion of <em><b>Foundations in Therapeutic Nutrition</b></em> introductory course. This workshop is a facilitated, online series of four 1.5-hour sessions that feature case-based learning and discipline-specific clinical competency development. Workshop size is limited and offered over four sessions to allow for consolidation of skills and experience in between each session. Workshop facilitators are discipline specific clinician/educators with advanced knowledge and skills in therapeutic nutrition. The workshop culminates with case study presentations by each participant.</p>
                            <p className="information-page-li"><b><em>Certificate in Therapeutic Nutrition from the IPTN.</em></b> Individuals who satisfactorily complete both levels of training can apply for this certification, which must be renewed every 2 years. Specific requirements of renewal will be shared with Certificate holders and will involve providing evidence of continued professional development in therapeutic nutrition, and submission of a case study for peer review. </p>
                        </div>
                    </section>
                    <section className="information-page-pricing">
                        <div className="information-page-subheader-container">
                            <h3 className="information-page-subheader">PRICING</h3>
                        </div>
                        <article className="information-page-price-option">
                            <div className="information-page-pricing-header">
                                <h4 className="information-page-pricing-header-text">FOR PHARMACISTS</h4>
                                <div className="information-page-pricing-header-price-container">
                                    <h5 className="information-page-pricing-header-price">$275</h5>
                                    {/* <h6 className="information-page-pricing-header-monthly">/month</h6> */}
                                </div>
                            </div>
                            <div className="information-page-pricing-information">
                                <ul className="information-page-pricing-unordered-list">
                                    <li className="information-page-li">This price is for Level I only (Foundations in Therapeutic Nutrition)</li>
                                    <li className="information-page-li">You have 6 months to complete Foundations and will receive an invitation to join a Level 2 Workshop once you meet this pre-requisite.</li>
                                    <li className="information-page-li">You will be emailed a link to the CANVAS e-learning platform following payment and registration</li>
                                </ul>
                                <a href="https://buy.stripe.com/9AQ7tU1WO1zHg1idQQ" className="information-page-pricing-register-button">Purchase</a>
                            </div>
                        </article>
                        {/* <article className="information-page-price-option">
                            <div className="information-page-pricing-header">
                                <h4 className="information-page-pricing-header-text">GROUP COACHING</h4>
                                <div className="information-page-pricing-header-price-container">
                                    <h5 className="information-page-pricing-header-price">$100</h5>
                                    <h6 className="information-page-pricing-header-monthly">/month</h6>
                                </div>
                            </div>
                                <div className="information-page-pricing-information">
                                    <ul className="information-page-pricing-unordered-list">
                                        <li className="information-page-li">Exclusive Weekly Lessons in fitness and nutrition</li>
                                        <li className="information-page-li">Access to habit tracker, exercise demos and in depth tutorials all in one easy to use place</li>
                                        <li className="information-page-li">Access to Video Exercise Library</li>
                                        <li className="information-page-li">Access to thriving Facebook Community</li>
                                    </ul>
                                    <Link to="/signup-1-on-1" className="information-page-pricing-register-button">Register</Link>
                            </div>
                        </article> */}
                    </section>
                    <Footer />
                </div>
            </div>
        )
    }

}

export default Home;